<template>
  <div class="base-page">
    <Header />
    <router-view />
  </div>
</template>

<script>

import Header from '@component/Part/Header';

export default {
  components: {
    Header
  },
};
</script>
